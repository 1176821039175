import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  public url: string = 'http://localhost:3000';
  public api: string = 'https://integracion.gruponueveonce.com/api/plansgame';

  constructor(
    private httpClient: HttpClient
  ) { }

  getSurvey(): Observable<any> {
    return this.httpClient.get(this.url + '/getSurvey');
  }

  postSendResults(body): Observable<any> {
    return this.httpClient.post(this.api, body);
  }

}
