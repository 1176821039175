<div class="wrapper">
  <!-- Toolbar -->
  <div class="toolbar" role="banner">
    <img class="toolbar-img" width="250" alt="Logo" src="assets/logos/logo.svg"/>
  </div>

  <!-- Content -->
  <div class="content" role="main">
    <div class="container">
      <router-outlet></router-outlet>
    </div>
    <footer class="footer">
      <p>2022 Grupo Nueve Once. Todos los derechos reservados.</p>
    </footer>
  </div>
</div>