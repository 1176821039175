<div class="modal-header">
    <button type="button" class="close" data-dismiss="modal" (click)="onConfirmClick()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <h1 class="title">{{message.principal_message}}</h1>
    <p class="subtitle">{{message.second_message}}</p>
    <p class="extra" *ngIf="message.extra_message">{{message.extra_message}}</p>
    <div>
        <img *ngIf="message.id === 1" width="200" src="../../../assets/modal1.png" alt="exclamation" class="img">
        <img *ngIf="message.id === 2" width="200" src="../../../assets/modal2.png" alt="exclamation" class="img">
    </div>
    <button type="button" class="f_btn prev_btn" (click)="onConfirmClick()"> Continuar </button>
</div>
<br>